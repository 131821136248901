$(document).ready(function() {
	// check if in iframe
	if (window.self === window.top) {
		return;
	}

	// PV_to_EZ_imp_version_1.0
	console.log('>>> eZair Document READY for Penpal setup!');
	Penpal.Promise = RSVP.Promise;

	// Initialize parentConnection and setup after Collecting parent.requestSettings
	let parentConnection = Penpal.connectToParent({}); // Defer to Penpal defaults...
	parentConnection.promise.then(function(parent) {
		// console.log('>>> CONFIRMED parentConnection:'); // , parent);
		parent.requestSettings().then(function(settings) {
			// console.log('>>> CONFIRMED requestSettings:', settings);
			// Condition values
			let requestSettings = settings;
			let lastDocHeight = 0;
			let confirmedFlightDetailsLink = '';

			// Retrieve tasks
			let grabHeight = function() {
				return $('#container').height();
			};
			let grabAAAconfirmLink = function() {
				return $('#getAAAItineraryLink').val();
			};

			// Initial UI setup
			parent.adjustIframeHeight(grabHeight());

			// Start listening for height adjustment and AAA selections...
			setInterval(function() {
				// IFRAME HEIGHT
				let currentDocHeight = grabHeight();
				if (currentDocHeight !== lastDocHeight) {
					lastDocHeight = currentDocHeight;
					parent.adjustIframeHeight(currentDocHeight);
					// console.log('>> child requested adjustIframeHeight...');
				}

				// CONFIRM AAA SELECTION
				if (requestSettings.AAAshopping) {
					let flightDetailsLink = grabAAAconfirmLink();
					if (flightDetailsLink !== undefined && confirmedFlightDetailsLink === '') {
						confirmedFlightDetailsLink = flightDetailsLink;
						parent.registerConfirmedAAAselection(flightDetailsLink);
						// console.log('>> child requested registerConfirmedAAAselection...');
					}
				}
			}, 500);
		}); // requestSettings
	}); // parentConnection
}); // doc.ready
